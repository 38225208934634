// @flow
import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import handleViewport from 'react-in-viewport';

import { calculateNoOfTrees, toDanishCurrencyString } from '../../utils/util';
import Trees from '../trees/trees';

type Props = {
  CO2Saving: string,
  allImprovements: boolean,
  allTabProfitable: boolean,
  classes: any,
};

const InViewportTrees = handleViewport(Trees, { threshold: 1 });

const styles = () => ({
  container: {
    display: 'flex',
    height: '100%',
    padding: '26px 20px 26px 20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    border: '1px solid #e8e8e8',
  },
  treeBody: {
    textAlign: 'center',
    width: '100%',
    color: '#616266',
    fontWeight: 'bold',
    fontSize: '25px',
  },
  treeBox: {
    display: 'flex',
    color: '#616266',
    justifyContent: 'center',
    textAlign: 'center',
  },
  treeAnimation: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '30px',
  },
  treeAnimationContainer: {
    maxWidth: '400px',
    height: '271px',
    position: 'relative',
    top: '50%',
    left: '50%',
    '-webkitTransform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
  },
  treeContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  treeBodyHighlight: {
    color: '#003764',
    fontWeight: 'bold',
    display: 'inline-block',
  },
  treeSavedCount: {
    color: '#003764',
    fontWeight: 'bold',
    fontSize: '25px',
    lineHeight: '20px',
    textAlign: 'center',
    marginBottom: '13px',
  },
  treeDescription: {
    width: '100%',
  },
  treeSavedCountDescription: {
    textAlign: 'center',
    color: '#616266',
    fontSize: '16px',
  },
});

function TreeCard(props: Props) {
  const { allImprovements, allTabProfitable, CO2Saving, classes } = props;

  const treesData = calculateNoOfTrees(parseFloat(CO2Saving));

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
      className={classes.container}
    >
      <Grid item className={classes.treeBody}>
        <Typography variant="body1">
          {allImprovements
            ? `${
                allTabProfitable
                  ? 'Udføres alle rentable forbedringer'
                  : 'Udføres alle renoveringer, '
              }`
            : ' Med denne energiforbedring'}{' '}
          skåner du miljøet for
          <span className={classes.treeBodyHighlight}>
            &nbsp;
            {`${toDanishCurrencyString(parseFloat(CO2Saving), 2)} ton CO2`}
            &nbsp;
          </span>
          om året.
        </Typography>
      </Grid>

      <Grid container className={classes.treeContainer}>
        <InViewportTrees treesData={treesData} />
      </Grid>

      <Grid item className={classes.treeDescription}>
        <Typography
          variant="body1"
          className={classes.treeSavedCountDescription}
        >
          kan hvert år optage den samme mængde CO2 fra luften.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(TreeCard);
