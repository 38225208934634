// @flow
import { Component, createElement } from 'react';
import anime from 'animejs';
import { ReactComponent as Moneybag1 } from '../../images/moneybag1.svg';
import { ReactComponent as Moneybag1Dark } from '../../images/moneybag1_dark.svg';

type Props = {
  id: number,
  moneybagVariant: 'moneybag1' | 'moneybag1Dark',
  play?: boolean,
  delay?: number,
};

class Moneybag extends Component<Props> {
  moneybagComponents = {
    moneybag1: createElement(Moneybag1, { className: this.props.id }),
    moneybag1Dark: createElement(Moneybag1Dark, { className: this.props.id }),
  };

  targets = {
    moneybag1: {
      shadow: '.mb0',
      bag: '.mb1, .mb2, .mb3',
    },
    moneybag1Dark: {
      shadow: '.mbd0',
      bag: '.mb1, .mb2, .mb3',
    },
  };

  componentDidMount = () => {
    this.timeline = anime.timeline({
      easing: 'easeInOutCubic',
      delay: 600 + this.props.delay,
      duration: 750,
      autoplay: false,
    });

    this.timeline
      .add(
        {
          targets: `.${this.props.id} ${this.targets[this.props.moneybagVariant].shadow}`,
          scaleX: [0, 1],
        },
        0
      )
      .add(
        {
          targets: `.${this.props.id} .bag`,
          translateY: [
            { value: ['-150%', '-150%'] },
            { value: 0 },
            { value: 0 },
          ],
          scaleY: [
            { value: [0, 1] },
            { value: [1, 1] },
            { value: [1, 0.7] },
            { value: [0.7, 1] },
          ],
          scaleX: [1, 1, 1.2, 1],
        },
        0
      );
  };

  componentDidUpdate = nextProps => {
    if (this.props.play !== nextProps.play && nextProps.play) {
      this.timeline.play();
    }
  };

  render() {
    return this.moneybagComponents[this.props.moneybagVariant];
  }
}

Moneybag.defaultProps = {
  delay: 0,
  play: false,
};

export default Moneybag;
