// @flow
import React from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
// import styled from 'styled-components';
import handleViewport from 'react-in-viewport';

import Moneybags from '../moneybags/moneybags';

import {
  calculateMoneybagsAnimation,
  toDanishCurrencyString,
} from '../../utils/util';

const InViewportMoneybags = handleViewport(Moneybags, { threshold: 1 });

type Props = {
  allImprovements: boolean,
  classes: any,
  MoneySaving: number,
  Investment: number,
  allTabProfitable: boolean,
  profitable: string,
};

const styles = () => ({
  container: {
    height: '100%',
    padding: '26px 20px 26px 20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    border: '1px solid #e8e8e8',
  },
  moneyBagTitle: {
    textAlign: 'center',
    width: '100%',
    // margin: '30px 73px 10px',
    color: '#616266',
  },
  moneyBagTitleHighlight: {
    color: '#003764',
    fontWeight: 'bold',
  },
  moneyBagImageContainer: {
    width: '100%',
  },
  moneyBagSavedContainer: {
    width: '100%',
  },
  moneyBagSaved: {
    color: '#003764',
    fontWeight: 'bold',
    fontSize: '25px',
    lineHeight: '20px',
    textAlign: 'center',
    marginBottom: '13px',
  },

  moneyBagSavedText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#616266',
  },
});

function MoneyBagCard(props: Props) {
  const {
    allImprovements,
    classes,
    Investment,
    MoneySaving,
    profitable,
    allTabProfitable,
  } = props;
  const years = Math.round(Investment / MoneySaving);

  const moneybagsAnimation = calculateMoneybagsAnimation(MoneySaving);

  const initialText = () => {
    return allImprovements
      ? 'Alle rentable forbedringer'
      : 'Denne energiforbedring';
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
      className={classes.container}
    >
      <Grid item className={classes.moneyBagTitle}>
        {profitable === 'true' ? (
          <Typography variant="body1">
            {initialText()} koster
            <span className={classes.moneyBagTitleHighlight}>
              &nbsp;
              {toDanishCurrencyString(Investment, 0)} kr. &nbsp;
            </span>
            og har en tilbagebetalingstid
            <span className={classes.moneyBagTitleHighlight}>
              {' '}
              på {years} år.{' '}
            </span>
          </Typography>
        ) : (
          <Typography variant="body1">
            Disse energiforbedringer anbefales kun i forbindelse med renovering.
            {/* Denne energiforbedring er kun rentabel ved renovering og koster
            <span className={classes.moneyBagTitleHighlight}>
              &nbsp;
              {toDanishCurrencyString(Investment, 0)} kr. &nbsp;
            </span> */}
          </Typography>
        )}
      </Grid>

      <Grid item className={classes.moneyBagImageContainer}>
        <InViewportMoneybags
          noOfMoney={MoneySaving}
          moneybagsAnimation={moneybagsAnimation}
        />
      </Grid>

      <Grid item className={classes.moneyBagSavedContainer}>
        <Typography variant="body1" className={classes.moneyBagSavedText}>
          er den årlige besparelse,{' '}
          {allImprovements
            ? `du kan opnå med alle de rentable forbedringer${
                allTabProfitable ? '' : ' ved renovering'
              }.`
            : 'du kan opnå med forbedringen.'}
        </Typography>
      </Grid>
    </Grid>
  );
}

// const MoneyBagsContainer = styled.div`
//   overflow: hidden;
//   max-width: 567px;
//   width: 100%;

//   & img {
//     transform: translateY(-100%) scale(0);
//   }
// `;

export default withStyles(styles)(MoneyBagCard);
