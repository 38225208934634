// @flow
import React from 'react';
import ReactGA from 'react-ga';

import { EmbedPage } from './pages';

import './App.css';

function App() {
  const propertyId =
    window.xprops && window.xprops.propertyId
      ? window.xprops.propertyId
      : '481-3482';
  // No profitable profilt:  494-7199, 1018281
  // Profilt: 481-3482, 256S445
  // No profilt: 370-4677, 6960176
  // Not found (404): 494-5852
  ReactGA.pageview(`/embeded/property/${propertyId}`);
  return <EmbedPage propertyId={propertyId} />;
}

export default App;
