// @flow
import React from 'react';
import { withStyles, Typography } from '@material-ui/core';

type Props = {
  classes: any,
  propertyData: any,
};

const styles = () => ({
  address: {
    color: '#202124',
    fontSize: '15px',
    fontWeight: 600,
  },
});

function Address(props: Props) {
  const { classes, propertyData } = props;
  const address =
    `${propertyData.street_name} ${propertyData.street_number}, ` +
    `${propertyData.post_code} ${propertyData.city}`;
  return (
    <Typography variant="h2" className={classes.address}>
      {address}
    </Typography>
  );
}

export default withStyles(styles)(Address);
