// @flow
import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

type TEnergyClass = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';

type Props = {
  energyClass: TEnergyClass,
  small: true | false,
  classes: any,
};

const styles = () => ({
  energyClassText: {
    color: '#FFF',
    fontSize: '20px',
    fontWeight: 600,
  },
  energyClassTextSmall: {
    color: '#FFF',
    fontSize: '11px',
    fontWeight: 600,
  },
});

function EnergyClass(props: Props) {
  const EnergyClassToColors = {
    A: '#33a357',
    B: '#67C32A',
    C: '#E3B217',
    D: '#E1490F',
    E: '#E1490F',
    F: '#E1490F',
    G: '#862052',
  };
  const { classes, energyClass, small } = props;

  return (
    <Container color={EnergyClassToColors[energyClass]} small={small}>
      <Typography
        className={
          small ? classes.energyClassTextSmall : classes.energyClassText
        }
      >
        {energyClass}
      </Typography>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  background-color: ${props => `${props.color}`};
  display: flex;
  justify-content: center;
  height: ${props => (props.small ? '22px' : '40px')};
  width: ${props => (props.small ? '22px' : '40px')};
`;

export default withStyles(styles)(EnergyClass);
