// @flow
import React from 'react';
import { Box, Button, Grid, withStyles } from '@material-ui/core';

import { websiteUrl } from '../../config';

import { ReactComponent as LogoSvg } from '../../images/Real_logo.svg';

const leaf = require('../../images/leaf_64x64.png');

type Props = {
  classes: any,
};

const Logo = withStyles({
  root: {
    marginLeft: '-8px',
    float: 'left',
  },
})(Box);

const styles = () => ({
  buttonContainer: {
    textAlign: 'left',
    '@media (min-width: 641px)': {
      textAlign: 'right',
    },
  },
  gridContainer: {
    justifyContent: 'center',
    flexWrap: 'nowrap',
    '@media (max-width: 640px)': {
      flexWrap: 'wrap',
      alignItems: 'flex-start',
    },
  },
});
const OpenStandaloneButton = withStyles({
  root: {
    background: '#003761',
    borderRadius: 27,
    border: 0,
    color: '#FFFFFF',
    height: 46,
    width: 300,
    textAlign: 'center',
    letterSpacing: '1.6px',
    fontSize: '15px',
    '&:hover': {
      backgroundColor: 'rgba(0, 55, 97, 0.8)',
    },
    '@media (max-width: 640px)': {
      marginTop: '15px',
      width: 260,
    },
  },
  label: {
    textTransform: 'uppercase',
  },
})(Button);

function Headline(props: Props) {
  const { classes } = props;
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignContent="flex-start"
      className={classes.gridContainer}
    >
      <Grid item xs={12} md={6} lg={6}>
        <Logo>
          {/* <LogoSvg style={{ height: '46px' }} /> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '8px',
            }}
          >
            <img src={leaf} alt="Leaf" width="30px" height="30px" />
            <span
              style={{
                color: '#003761',
                fontSize: '28px',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontWeight: '500',
                marginLeft: '7px',
              }}
            >
              Min Klimaberegner
            </span>
          </div>
        </Logo>
      </Grid>
      <Grid item xs={12} md={6} lg={6} className={classes.buttonContainer}>
        <OpenStandaloneButton target="_blank" href={websiteUrl}>
          Prøv på din bolig
        </OpenStandaloneButton>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(Headline);
