import * as zoid from 'zoid/dist/zoid.frameworks';

import { widgetUrl } from '../config';

const MyZoidComponent = zoid.create({
  tag: 'climate-calculator',
  url: widgetUrl,
  dimensions: {
    width: '100%',
    height: '100%',
  },
  autoResize: {
    width: false,
    height: true,
  },
  scrolling: false,
});

// console.log('ClimateCalculator: widget load');

export default MyZoidComponent;
