// @flow
import { Component, createElement } from 'react';
import anime from 'animejs';
import { ReactComponent as Tree1 } from '../../images/tree1.svg';
import { ReactComponent as Tree2 } from '../../images/tree2.svg';
import { ReactComponent as Tree3 } from '../../images/tree3.svg';
import { ReactComponent as Tree4 } from '../../images/tree4.svg';
import { ReactComponent as Tree5 } from '../../images/tree5.svg';
import { ReactComponent as Tree1Dark } from '../../images/tree1_dark.svg';
import { ReactComponent as Tree2Dark } from '../../images/tree2_dark.svg';
import { ReactComponent as Tree3Dark } from '../../images/tree3_dark.svg';
import { ReactComponent as Tree4Dark } from '../../images/tree4_dark.svg';
import { ReactComponent as Tree5Dark } from '../../images/tree5_dark.svg';

type Props = {
  treeVariant:
    | 'tree1'
    | 'tree2'
    | 'tree3'
    | 'tree4'
    | 'tree5'
    | 'tree1Dark'
    | 'tree2Dark'
    | 'tree3Dark'
    | 'tree4Dark'
    | 'tree5Dark',
  playImmediately?: boolean,
  play?: boolean,
  delay?: number,
};

class Tree extends Component<Props> {
  treeComponents = {
    tree1: createElement(Tree1),
    tree2: createElement(Tree2),
    tree3: createElement(Tree3),
    tree4: createElement(Tree4),
    tree5: createElement(Tree5),
    tree1Dark: createElement(Tree1Dark),
    tree2Dark: createElement(Tree2Dark),
    tree3Dark: createElement(Tree3Dark),
    tree4Dark: createElement(Tree4Dark),
    tree5Dark: createElement(Tree5Dark),
  };

  targets = {
    tree1: {
      shadow: '.t10',
      trunk: '.t11, .t12',
      leaves: '.t13, .t14',
    },
    tree2: {
      shadow: '.t20',
      trunk: '.t21',
      leaves: '.t22, .t23',
    },
    tree3: {
      shadow: '.t30',
      trunk: '.t31, .t32',
      leaves: '.t33, .t34',
    },
    tree4: {
      shadow: '.t40',
      trunk: '.t41, .t42',
      leaves: '.t43, .t44, .t45',
    },
    tree5: {
      shadow: '.t50',
      trunk: '.t51',
      leaves: '.t52, .t53',
    },
    tree1Dark: {
      shadow: '.td10',
      trunk: '.td11, .td12',
      leaves: '.td13, .td14',
    },
    tree2Dark: {
      shadow: '.td20',
      trunk: '.td21',
      leaves: '.td22, .td23',
    },
    tree3Dark: {
      shadow: '.td30',
      trunk: '.td31, .td32',
      leaves: '.td33, .td34',
    },
    tree4Dark: {
      shadow: '.td40',
      trunk: '.td41, .td42',
      leaves: '.td43, .td44, .td45',
    },
    tree5Dark: {
      shadow: '.td50',
      trunk: '.td51',
      leaves: '.td52, .td53',
    },
  };

  componentDidMount = () => {
    const { delay, treeVariant, playImmediately } = this.props;

    this.timeline = anime.timeline({
      easing: 'easeInOutCubic',
      delay: 600 + delay,
      duration: 1250,
      autoplay: false,
    });

    this.timeline
      .add(
        {
          targets: this.targets[treeVariant].shadow,
          scale: [
            { value: [0, 1] },
            { value: [1, [1.1, 0.9]] },
            {
              value: [
                [1.1, 0.9],
                [0.9, 1.1],
              ],
            },
            {
              value: [
                [0.9, 1.1],
                [1.05, 0.95],
              ],
            },
            {
              value: [
                [1.05, 0.95],
                [1, 1],
              ],
            },
          ],
        },
        0
      )
      .add(
        {
          targets: this.targets[treeVariant].trunk,
          scale: [
            {
              value: [
                [0, 0],
                [1, 0.2],
              ],
            },
            {
              value: [
                [1, 0.2],
                [1.1, 0.4],
              ],
            },
            {
              value: [
                [1.1, 0.4],
                [0.9, 1.7],
              ],
            },
            {
              value: [
                [0.9, 1.1],
                [1.05, 0.9],
              ],
            },
            {
              value: [
                [1.05, 0.9],
                [1, 1],
              ],
            },
          ],
        },
        0
      )
      .add(
        {
          targets: this.targets[treeVariant].leaves,
          scale: [
            { value: [0, 1] },
            { value: [1, [1.1, 0.9]] },
            {
              value: [
                [1.1, 0.9],
                [0.9, 1.1],
              ],
            },
            {
              value: [
                [0.9, 1.1],
                [1.05, 0.95],
              ],
            },
            {
              value: [
                [1.05, 0.95],
                [1, 1],
              ],
            },
          ],
        },
        0
      );

    if (playImmediately) {
      this.timeline.play();
    }
  };

  componentDidUpdate = nextProps => {
    const { play } = this.props;
    if (play !== nextProps.play && nextProps.play) {
      this.timeline.play();
    }
  };

  render() {
    const { treeVariant } = this.props;
    return this.treeComponents[treeVariant];
  }
}

Tree.defaultProps = {
  playImmediately: false,
  delay: 0,
  play: false,
};

export default Tree;
