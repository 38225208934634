const apiBase =
  process.env.REACT_APP_ENV === 'staging'
    ? 'https://stagingapi.klimaberegneren.dk/staging_panel/api'
    : 'https://api.klimaberegneren.dk/api_panel/api';

const gaCode =
  process.env.REACT_APP_ENV === 'staging' ? 'UA-103640126-4' : 'UA-103640126-4';

const websiteUrl =
  process.env.REACT_APP_ENV === 'staging'
    ? 'http://staging-co2calculator.s3-website.eu-central-1.amazonaws.com/'
    : 'https://klimaberegner.realmaeglerne.dk/?utm_source=embedded-klimaberegner&utm_medium=website&utm_campaign=Realmaeglernes%20webiste';

const widgetUrl =
  process.env.REACT_APP_ENV === 'staging'
    ? 'https://staging-embedded.klimaberegneren.dk/index.html'
    : 'https://embeded.klimaberegneren.dk/index.html';

export { apiBase, gaCode, websiteUrl, widgetUrl };
