// @flow
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useCountUp } from 'react-countup';
import { Box, Typography } from '@material-ui/core';
import Moneybag from '../moneybag/moneybag';

type Props = {
  noOfMoney: any,
  forwardedRef: any,
  inViewport: boolean,
  enterCount: number,
  moneybagsAnimation: number,
};

const moneybagsCombinations = {
  '6': [
    {
      id: 'moneybag1',
      type: 'moneybag1Dark',
      style: {
        left: 0,
        bottom: '22.8%',
        maxWidth: '19.29%',
        zIndex: 0,
      },
    },
    {
      id: 'moneybag2',
      type: 'moneybag1',
      style: {
        bottom: 0,
        left: '7.78%',
        maxWidth: '34%',
        zIndex: 2,
      },
    },
    {
      id: 'moneybag3',
      type: 'moneybag1',
      style: {
        bottom: '11.7%',
        left: '28.4%',
        maxWidth: '29.10%',
        zIndex: 1,
      },
    },
    {
      id: 'moneybag4',
      type: 'moneybag1Dark',
      style: {
        bottom: '21.6%',
        left: '46.8%',
        maxWidth: '26%',
        zIndex: 0,
      },
    },
    {
      id: 'moneybag5',
      type: 'moneybag1',
      style: {
        bottom: 0,
        left: '57%',
        maxWidth: '19.5%',
        zIndex: 2,
      },
    },
    {
      id: 'moneybag6',
      type: 'moneybag1',
      style: {
        bottom: '2.4%',
        left: '67.1%',
        maxWidth: '26.1%',
      },
    },
  ],
  '5': [
    {
      id: 'moneybag1',
      type: 'moneybag1',
      style: {
        bottom: 0,
        left: '2.1%',
        maxWidth: '34%',
        zIndex: 1,
      },
    },
    {
      id: 'moneybag2',
      type: 'moneybag1',
      style: {
        bottom: '11.4%',
        left: '24.8%',
        maxWidth: '26%',
        zIndex: 0,
      },
    },
    {
      id: 'moneybag3',
      type: 'moneybag1',
      style: {
        bottom: '1%',
        left: '40.1%',
        maxWidth: '28.5%',
        zIndex: 1,
      },
    },
    {
      id: 'moneybag1',
      type: 'moneybag1Dark',
      style: {
        bottom: '17.8%',
        left: '56%',
        maxWidth: '28.29%',
        zIndex: 0,
      },
    },
    {
      id: 'moneybag2',
      type: 'moneybag1',
      style: {
        bottom: '2.6%',
        left: '72.2%',
        maxWidth: '20.2%',
        zIndex: 0,
      },
    },
  ],
  '4': [
    {
      id: 'moneybag1',
      type: 'moneybag1',
      style: {
        bottom: 0,
        left: '4%',
        maxWidth: '28.2%',
        zIndex: 1,
      },
    },
    {
      id: 'moneybag2',
      type: 'moneybag1',
      style: {
        bottom: '11%',
        left: '26.2%',
        maxWidth: '21%',
        zIndex: 0,
      },
    },
    {
      id: 'moneybag3',
      type: 'moneybag1',
      style: {
        bottom: 0,
        left: '40.1%',
        maxWidth: '33.7%',
        zIndex: 1,
      },
    },
    {
      id: 'moneybag2',
      type: 'moneybag1',
      style: {
        bottom: '8.8%',
        left: '66%',
        maxWidth: '26.4%',
        zIndex: 0,
      },
    },
  ],
  '3': [
    {
      id: 'moneybag1',
      type: 'moneybag1',
      style: {
        bottom: 0,
        left: '7%',
        maxWidth: '34%',
        zIndex: 1,
      },
    },
    {
      id: 'moneybag2',
      type: 'moneybag1',
      style: {
        bottom: '18.4%',
        left: '35%',
        maxWidth: '23%',
        zIndex: 0,
      },
    },
    {
      id: 'moneybag3',
      type: 'moneybag1',
      style: {
        bottom: '2%',
        left: '51.5%',
        maxWidth: '32.3%',
        zIndex: 1,
      },
    },
  ],
  '2': [
    {
      id: 'moneybag1',
      type: 'moneybag1',
      style: {
        bottom: 0,
        left: '21%',
        maxWidth: '33%',
        zIndex: 1,
      },
    },
    {
      id: 'moneybag2',
      type: 'moneybag1',
      style: {
        bottom: '8.5%',
        left: '48.5%',
        maxWidth: '22.5%',
        zIndex: 0,
      },
    },
  ],
  '1': [
    {
      id: 'moneybag1',
      type: 'moneybag1',
      style: {
        bottom: 0,
        left: '34%',
        maxWidth: '32%',
        zIndex: 1,
      },
    },
  ],
};

export default function Moneybags(props: Props) {
  const {
    noOfMoney,
    moneybagsAnimation,
    inViewport,
    enterCount,
    forwardedRef,
  } = props;

  const { countUp, start } = useCountUp({
    start: 0,
    end: noOfMoney,
    delay: 0,
    duration: 1.5,
    separator: '.',
    suffix: ' kr.',
    decimals: 0,
    preserveValue: true,
  });

  useEffect(() => {
    if (inViewport && enterCount === 0) {
      start();
    }
  }, [enterCount, inViewport, start]);

  return (
    <Container ref={forwardedRef}>
      <MoneybagsContainer className="moneybags-container">
        {moneybagsCombinations[moneybagsAnimation].map((moneybag, i) => {
          return (
            <MoneybagContainer key={moneybag.id + i} style={moneybag.style}>
              <Moneybag
                id={moneybag.id}
                delay={Math.floor(Math.random() * 500)}
                moneybagVariant={moneybag.type}
                play={inViewport && enterCount === 0}
              />
            </MoneybagContainer>
          );
        })}
      </MoneybagsContainer>
      <Box color="#202124" fontSize="30px" fontWeight={600} textAlign="center">
        <Box
          color="#003764"
          fontSize="28px"
          display="inline-block"
          fontWeight={600}
        >
          <span>{countUp}</span>
        </Box>
      </Box>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 315px;
  position: relative;
  width: 100%;
  margin: auto;
  // border: 1px solid red;
`;

const MoneybagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  // height: 100%;
  justify-content: center;
  min-height: 119px;
  margin: 90px 0 35px 0;
  position: relative;
  width: 100%;
`;

const MoneybagContainer = styled.div`
  position: absolute;
  max-width: 100px;
  width: 100%;
`;
