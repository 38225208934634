// @flow
import React from 'react';
import { withStyles, Box, Grid } from '@material-ui/core';

import ProgressBar from '../progress-bar/progress-bar';
import ExpandText from '../expand-text/expand-text';
import ExpandTitle from '../expand-text/expand-title';

type Props = {
  title: string,
  greenImprovementValue: number,
  description: string,
  profitable: string,
  classes: any,
};

const styles = () => ({
  container: {
    height: '100%',
    padding: '26px 20px 26px 20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    border: '1px solid #e8e8e8',
  },
  title: {
    width: '100%',
    marginBottom: '21px',
    color: '#003761',
    fontSize: '21px',
    fontWeight: '500',
    lineHeight: '1.5',
  },
  progressBar: {
    width: '100%',
    height: '40px',
    fontSize: '13px',
    fontWeight: '500',
    color: '#7b7d82',
    marginBottom: '21px',
  },
  description: {
    width: '100%',
    color: '#7b7d82',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    '& b': {
      color: '#003764',
      fontSize: '16px',
      fontWeight: '500',
    },
  },
});

function ImprovementsCard(props: Props) {
  const {
    title,
    description,
    greenImprovementValue,
    profitable,
    classes,
  } = props;

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
      className={classes.container}
    >
      <Grid item className={classes.title}>
        <ExpandTitle heightLimit={57}>
          <span>{title}</span>
        </ExpandTitle>
      </Grid>
      <Grid item className={classes.progressBar}>
        <Box h={1}>
          {profitable === 'true' && (
            <span>
              GRØN FORBEDRING:{' '}
              {
                <Box display="inline-block" fontWeight={500}>
                  {`${greenImprovementValue}%`}
                </Box>
              }
            </span>
          )}
          <ProgressBar profitable={profitable} value={greenImprovementValue} />
        </Box>
      </Grid>
      <Grid item className={classes.description}>
        <ExpandText heightLimit={200}>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </ExpandText>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(ImprovementsCard);
