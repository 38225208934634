// @flow
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';

type Props = {
  classes: any,
  value: number,
  profitable: string,
};

const styles = () => ({
  ProgressBar: {
    position: 'relative',
    top: 10,
    background: '#E5E5EB',
    height: '6px',
    borderRadius: '3px',
    opacity: 0.9,
  },
  Filler: {
    background: '#13A970',
    height: '100%',
    borderRadius: 'inherit',
    transition: 'width .5s ease-in',
  },
});

function ProgressBar(props: Props) {
  const { classes, value, profitable } = props;

  const [width, setWidth] = useState('0%');

  useEffect(() => {
    const timer = setTimeout(() => {
      setWidth(`${value}%`);
    }, 100);
    return () => clearTimeout(timer);
  }, [value]);

  if (profitable === 'false') return <></>;

  return (
    <div className={classes.ProgressBar}>
      <div className={classes.Filler} style={{ width }} />
    </div>
  );
}

export default withStyles(styles)(ProgressBar);
