// @flow
import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

const Container = styled.div`
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  display: flex;
`;

const MessageIconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 30px;
`;

const MessageIcon = styled(ChatBubbleOutlineIcon)`
  color: #13a970;
  transform: scaleX(-1);
`;

type Props = {
  title: string,
  subtitle: string,
};

function QuestionAnswer(props: Props) {
  const { title, subtitle } = props;

  return (
    <Container>
      <MessageIconContainer>
        <MessageIcon fontSize="small" />
      </MessageIconContainer>
      <Box
        alignItems="flex-start"
        paddingTop="28px"
        paddingBottom="28px"
        paddingRight="15px"
        display="flex"
        flexDirection="column"
      >
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </Box>
    </Container>
  );
}

export default QuestionAnswer;
