// @flow
import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { Headline } from '../../components';
import Trees from '../../images/trees-composed-07.png';

const styles = () => ({
  content: {
    width: 'auto',
    margin: '0 auto',
    overflow: 'hidden',
    backgroundColor: '#eee',
    padding: '40px 0px 40px 0px',
  },
  centeredContainer: {
    textAlign: 'center',
  },
  centeredBox: {
    marginBottom: '50px',
    marginTop: '30px',
  },
  flexIEFix: {
    flex: '1 1 auto',
  },
  headerSubtitle: {
    marginTop: '15px',
  },
  trees: {
    width: '85%',
  },
  bottomTitle: {
    color: '#202124',
    fontSize: '25px',
    fontWeight: 400,
    marginBottom: '10px',
  },
  bottomSubtitle: {
    color: '#616266',
    fontSize: '15px',
    fontWeight: 'normal',
    margin: 'auto',
  },
});

type Props = {
  classes: any,
  propertyData: any,
};

function EmbedEmNoResultsPage(props: Props) {
  const { classes, propertyData } = props;
  return (
    <div className={classes.content}>
      {propertyData.name}

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid key="header" className={classes.flexIEFix} item xs={12}>
          <Box marginBottom="30px">
            <Headline />
            <Typography variant="subtitle1" className={classes.headerSubtitle}>
              Hvad betyder energiforbedringer af boligen for klimaet og for din
              økonomi? Bliv klogere med klimaberegneren – som straks viser dig
              gevinsten ved de forbedringer, du ønsker.
            </Typography>
          </Box>
        </Grid>
        <Grid
          container
          className={[classes.flexIEFix, classes.centeredContainer].join(' ')}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={9} lg={5}>
            <Box className={classes.centeredBox}>
              <img src={Trees} alt="Trees" className={classes.trees} />
            </Box>
          </Grid>

          <Grid item xs={12} lg={8}>
            <Typography variant="h2" className={classes.bottomTitle}>
              Der findes ingen forbedringsforslag på adressen
            </Typography>
            <Typography variant="subtitle1" className={classes.bottomSubtitle}>
              Boligens energimærke indeholder ikke nogen rentable
              forbedringsforslag.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(EmbedEmNoResultsPage);
